import ScrollMagic from 'scrollmagic';
import {TimelineMax} from 'gsap';

export default class CoverAlt{

    constructor(element) {
        this.$coverAlt = element;
        this.$top = $('.js-cover-alt-top');
        this.$page = $('.js-cover-alt-page');
        this.$img = $('.js-cover-alt-img');
        this.controller = new ScrollMagic.Controller();
        this.timeline = new TimelineMax({pause:true});


        this.init();
    }

    init(){
        this.timeline.fromTo(this.$img,0.5, {scale:'0.8'}, {scale:'1'});
        this.scene = new ScrollMagic.Scene({
            triggerElement:$('body')[0],
            triggerHook:0,
            duration:500,
        })
            .setTween(this.timeline)
            .addTo(this.controller);
    }


}