export default class Countdown{

    constructor(element) {
        this.$wrapper = element;
        this.timestamp = this.$wrapper.data('ts');
        this.timestamp = this.timestamp*1000;

        this.init();
        setInterval(() => {
            this.init();
        },1000)
    }

    init(){
        let string = '';
        this.now = new Date().getTime();
        this.distance = this.timestamp - this.now;
        let days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((this.distance % (1000 * 60)) / 1000);

        if(days){
            //if only one character, add a 0 before
            if(days.toString().length == 1){
                days = '0'+days;
            }
            string += days+'j ';
        }
        if(hours){
            //if only one character, add a 0 before
            if(hours.toString().length == 1){
                hours = '0'+hours;
            }
            string += hours+'h';
        } else {
            string += '00h';
        }
        if(minutes){
            //if only one character, add a 0 before
            if(minutes.toString().length == 1){
                minutes = '0'+minutes;
            }
            string += ' : ' + minutes+'m';
        } else {
            string += ' : 00m';
        }
        if(seconds){
            //if only one character, add a 0 before
            if(seconds.toString().length == 1){
                seconds = '0'+seconds;
            }
            string += ' : ' + seconds+'s';
        } else {
            string += ' : 00s';
        }
        this.$wrapper.text(string);

    }

}