export default class Flocker {
    constructor(element) {
        this.$wrapper = element;
        this.$triggers = this.$wrapper.find('.js-flocker-trigger');
        this.$feeds = this.$wrapper.find('.js-flocker-feed');
        this.$btns = this.$wrapper.find('.js-flocker-btn');

        setTimeout(() => {
            this.$feeds.hide();
            this.$btns.hide();
            $(this.$feeds[0]).show();
            $(this.$btns[0]).show();
        },500);
        this.bindEvents();
    }

    bindEvents(){
        this.$triggers.on('click', this.showFeed.bind(this));
        $(document).on('click', '.flockler-grid-item', (e) => {
          let $currentItem = $(e.currentTarget);
          let $link = $currentItem.find('.flockler-grid-item__context__source');
          let url = $link.attr('href');
            window.open(url, '_blank').focus();
        })
    }

    showFeed(e){
        let $currentTarget = $(e.currentTarget);
        let dataFeed = $currentTarget.data('target');
        let $currentFeed = this.$wrapper.find('.js-flocker-feed[data-fl-id="'+dataFeed+'"]');
        let $currentBtn = this.$wrapper.find('.js-flocker-btn[data-fl-id="'+dataFeed+'"]');

        this.$feeds.hide();
        this.$btns.hide();
        this.$triggers.removeClass('is-active');
        $currentFeed.show();
        $currentBtn.show();
        $currentTarget.addClass('is-active');
    }
}
