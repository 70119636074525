import ScrollMagic from 'scrollmagic';
export default class PageIntroAnim{

    constructor(element) {
        this.$wrapper = element;
        this.$img = this.$wrapper.find('.js-page-intro-anim-img');

        this.controller = new ScrollMagic.Controller();
        this.timeline = new TimelineMax();
        this.timeline.to(this.$img, 1, {scale:1.2, y:'-10%'});

        this.scene = new ScrollMagic.Scene({
            triggerElement:this.$wrapper[0],
            triggerHook:0,
            duration:this.$wrapper.height()
        })
            .setTween(this.timeline)
            .addTo(this.controller);
    }
}