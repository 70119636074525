import ScrollMagic from 'scrollmagic';

export default class ProjectImgsAnim{

    constructor(element){

        this.$wrapper = element;
        this.$left = this.$wrapper.find('.js-project-imgs-anim-left');
        this.$right = this.$wrapper.find('.js-project-imgs-anim-right'); 

        this.$imgOneWrap = this.$wrapper.find('.js-project-imgs-anim-wrap-one');
        this.$imgTwoWrap = this.$wrapper.find('.js-project-imgs-anim-wrap-two');
        this.$imgThreeWrap = this.$wrapper.find('.js-project-imgs-anim-wrap-three');

        this.$imgOne = this.$wrapper.find('.js-project-imgs-anim-one');
        this.$imgTwo = this.$wrapper.find('.js-project-imgs-anim-two');
        this.$imgThree = this.$wrapper.find('.js-project-imgs-anim-three');

        this.resizeTimer = false;

        this.controller = new ScrollMagic.Controller();
        if(!this.isMobile()){
            this.buildAnim();
        }


        $(window).on('resize', this.handleResize.bind(this));


    }

    isMobile(){
        return window.matchMedia("(max-width:1025px)").matches;
    }

    handleResize(){
        clearTimeout(this.resizeTimer);

        this.resizeTimer = setTimeout(() => {
            this.controller.destroy(true);
            this.controller = new ScrollMagic.Controller();
            if(!this.isMobile()){
                this.buildAnim();
            }
        }, 99)

    }
 
    buildAnim(){
        TweenMax.set(this.$imgTwoWrap, {'width':this.$right.width()}); // Fix weird ScrollMagic Bug

        let firstScene = new ScrollMagic.Scene({
            offset:this.$wrapper.offset().top - $(window).height() + this.$imgTwoWrap.height() + 40,
            duration: this.$imgOneWrap.height() - this.$imgTwoWrap.height(),
            triggerHook:1
        }) 
        .setPin(this.$imgTwoWrap[0])
        // .addIndicators({name: "First scene"}) // add indicators (requires plugin)
        .addTo(this.controller); 

        let timeline = new TimelineMax();
        let $imgLeftFirstTween = TweenMax.to(this.$imgOne, 1, {scale:0.5, ease:Linear.easeNone});
        let $imgRightFirstTween = TweenMax.to(this.$imgTwo, 1, {scale:2, ease:Linear.easeNone});
        let $imgLeftTwoFirstTween = TweenMax.to(this.$imgThree, 1, {scale:0.5, ease:Linear.easeNone});
        
        timeline.add($imgLeftFirstTween, '0');
        timeline.add($imgRightFirstTween, '0');
        timeline.add($imgLeftTwoFirstTween, '0');

        let secondScene = new ScrollMagic.Scene({
            offset:firstScene.scrollOffset() + firstScene.duration(),
            duration: 1000,
            triggerHook:1
        })
        .setPin(this.$wrapper[0])
        .setTween(timeline)
        //.addIndicators({name: "Second scene"}) // add indicators (requires plugin)
        .addTo(this.controller); 


        let thirdScene = new ScrollMagic.Scene({offset:secondScene.scrollOffset() + secondScene.duration(), triggerHook:1, duration: this.$imgThreeWrap.height() + 40})
        .setPin(this.$imgTwoWrap[0])
        // .addIndicators({name: "Third scene"}) // add indicators (requires plugin)
        .addTo(this.controller); 

        let secondTimeline = new TimelineMax();
        let $imgLeftSecondTween = TweenMax.to(this.$imgOne, 1, {scale:1, ease:Linear.easeNone});
        let $imgRightSecondTween = TweenMax.to(this.$imgTwo, 1, {scale:1, ease:Linear.easeNone});
        let $imgLeftTwoSecondTween = TweenMax.to(this.$imgThree, 1, {scale:1, ease:Linear.easeNone});
        
        secondTimeline.add($imgLeftSecondTween, '0');
        secondTimeline.add($imgRightSecondTween, '0');
        secondTimeline.add($imgLeftTwoSecondTween, '0');

        let fourthScene = new ScrollMagic.Scene({offset:thirdScene.scrollOffset() + thirdScene .duration(), triggerHook:1, duration: 1000})
        .setPin(this.$wrapper[0])
        .setTween(secondTimeline)
        // .addIndicators({name: "fourth scene"}) // add indicators (requires plugin)
        .addTo(this.controller);  
    }
} 