import datepicker from 'js-datepicker'
export default class InputDate{

    constructor(element) {
        this.$input = element;

        this.init();
    }


    init(){
        this.picker = datepicker(this.$input[0], {
            formatter: (input, date, instance) => {
                const value = date.toLocaleDateString()
                input.value = value // => '1/1/2099'
            },
            position: 'c',
            customDays: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
            customMonths: [
                'Janvier',
                'Février',
                'Mars',
                'Avril',
                'Mai',
                'Juin',
                'Juillet',
                'Août',
                'Septembre',
                'Octobre',
                'Novembre',
                'Décembre']
        });
    }


}