export default class InteractiveMap{

    constructor(element) {
        this.$wrapper = element;
        this.$filters = this.$wrapper.find('.js-interactive-map-filter input');
        this.$btnSubmit = this.$wrapper.find('.js-interactive-map-filter-btn');
        this.$countries = this.$wrapper.find('.country');
        this.$items = this.$wrapper.find('.js-interactive-map-item');
        this.$mainLabel = this.$wrapper.find('.section-filter-toggle');
        this.$filter = this.$wrapper.find('.section-filter-item');
        this.$carouselMobile = this.$wrapper.find('.js-carousel-mobile');

        this.currentVal = '';

        this.bindEvents();
    }

    bindEvents(){
        this.$countries.on('click', (e) => {
            this.currentVal = $(e.currentTarget).attr('id');
            this.updateTpl()
        });


        this.$btnSubmit.on('click', () => {
            let $currentInput = this.$filters.filter(':checked');
            this.currentVal = $currentInput.val();
            this.$filter.removeClass('open');
            this.$mainLabel.focus();
            this.updateTpl();
        })
    }

    updateTpl(){
        let $oldItem = this.$items.filter('.is-active');
        let $currentCountry = this.$countries.filter('#'+this.currentVal);
        let $currentItem = this.$items.filter('[data-id='+this.currentVal+']');
        let $currentFilter = this.$filters.filter('[value='+this.currentVal+']');

        this.$countries.removeClass('is-active');


        TweenMax.fromTo($oldItem, 0.3, {y:'0%', alpha:1}, {y:'50px' , alpha:0, onComplete: () => {
                this.$items.removeClass('is-active');
                this.$filters.prop('checked',false);
                $currentItem.addClass('is-active');
                $currentFilter.prop('checked', true);
                $currentCountry.addClass('is-active');
                this.updateLabel($currentFilter);

                TweenMax.fromTo($currentItem, 0.3, {y:'50px', alpha:0}, {y:'0%' , alpha:1, onComplete:() => {
                    this.$carouselMobile.each((n,item) => {
                        item.carouselMobile.reset();
                    })
                    }
                });
            }
        });
    }

    updateLabel($filter){
        let $parent = $filter.parents('.custom-radio');
        let $label = $parent.find('.js-interactive-map-filter-label');
        this.$mainLabel.text($label.text());
    }


}