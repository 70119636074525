export default class JobboardFixed{

    constructor(element) {
        this.$wrapper = element;
        this.$bar = this.$wrapper.find('.js-jobboard-fixed-bar');

        this.bindEvents();
    }


    bindEvents(){
        $(window).on('scroll', this.onScroll.bind(this));
    }


    onScroll(){
        let scrollHeight = $(document).height() - $(window).height();
        let currScrollTop  = $(window).scrollTop();
        let headerHeight = $('header').height();

        let percent = currScrollTop / scrollHeight;

        TweenMax.set(this.$bar, {scaleX:percent});
        if(currScrollTop > headerHeight + 100){
            this.$wrapper.addClass('is-shown');
        }
        else{
            this.$wrapper.removeClass('is-shown');
        }
    }
}