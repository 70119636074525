export default class KeyfiguresImgSlider{

    constructor(element) {
        this.$wrapper = element;
        this.$slider = this.$wrapper.find('.js-keyfigures-img-slider-slider');
        this.$left = this.$wrapper.find('.js-keyfigures-img-slider-left');
        this.$right = this.$wrapper.find('.js-keyfigures-img-slider-right');
        this.$count = this.$wrapper.find('.js-keyfigures-img-slider-count');
        this.$dots = this.$wrapper.find('.js-keyfigures-img-slider-slider-dot');
        this.$sliderLeft = this.$wrapper.find('.js-keyfigures-img-slider-left');
        this.init();
        this.bindEvents();
    }

    bindEvents(){
        this.$left.on('click', () => {
            this.slider.prev();
        });
        this.$right.on('click', () => {
            this.slider.next();
        });
        this.$dots.on('click', (e) => {
            this.slider.goTo($(e.currentTarget).index());
        })
    }

    init(){

        if(this.$sliderLeft.length){
            this.sliderLeft = new Siema({
               selector:this.$sliderLeft[0],
               duration:400,
               easing: 'ease-out',
               perPage: 1,
               draggable:false,
            });
        }

        this.slider = new Siema({
            selector: this.$slider[0],
            duration: 400,
            easing: 'ease-out',
            perPage: 1,
            draggable: true,
            multipleDrag: true,
            loop: false,
            rtl: false,
            onChange:() => {
                this.$count.text(this.slider.currentSlide + 1);
                this.$dots.removeClass('is-active');
                $(this.$dots[this.slider.currentSlide]).addClass('is-active');
                if(this.$sliderLeft.length){
                    this.sliderLeft.goTo(this.slider.currentSlide);
                }
            },
        });
    }

}