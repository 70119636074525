export default class UploadInput{

    constructor(element){

        this.$ = {};

        this._bindDom(element);
        this._bindEvents();
    }

    _bindDom(element){

        this.$.wrapper = element[0];
        this.$.input = this.$.wrapper.querySelector('input[type="file"]');
        this.$.customBtn = document.createElement('div');
        this.$.customBtn.classList.add('js-upload-input__wrap');
        this.$.customBtn.innerHTML = this._buildTemplate();
        this.$.wrapper.appendChild(this.$.customBtn);
        this.$.trigger  = this.$.customBtn.querySelector('.js-upload-input__wrap__left');
        this.$.customBtnFilename = this.$.customBtn.querySelector('.js-upload-input__wrap__right .text');
        this.$.customBtnFilenameWrap = this.$.customBtn.querySelector('.js-upload-input__wrap__right');
        this.$.remove = this.$.customBtn.querySelector('.remove');
    }

    _bindEvents(){
        this.$.trigger.addEventListener('click', () => {
            this.$.input.click();
        });

        this.$.input.addEventListener('change', this._updateTemplate.bind(this));

        this.$.remove.addEventListener('click', this._removeValue.bind(this));
    }

    _buildTemplate(){
        return `
                <div class="js-upload-input__wrap__left">Choisir un ficher</div>
                <div class="js-upload-input__wrap__right"><span class="remove"></span><span class="text">Aucun fichier choisi...</span></div>
        `
    }

    _updateTemplate(){
        let filename = this.$.input.value;
        filename = filename.replace(/^.*\\/, ""); // Remove fakepath
        filename = filename != '' ? filename : 'Aucun fichier choisi...';

        this.$.customBtnFilename.innerText = filename;
        if(filename != 'Aucun fichier choisi...'){
            this.$.customBtnFilenameWrap.classList.add('has-value');
        }
        else{
            this.$.customBtnFilenameWrap.classList.remove('has-value');
        }
    }

    _removeValue(){
        this.$.input.value = "";
        this.$.input.dispatchEvent(new Event('change'));
    }

}