import ScrollMagic from 'scrollmagic';

export default class InnovationA{

    constructor(element){

        this.$wrapper = element;
        this.$title = element.find('.section-title');
        this.$intro = element.find('.section-intro');
        this.$btn = element.find('.button');

        this.controller = new ScrollMagic.Controller();
        this.timeline = new TimelineMax({pause:true});

        this.buildAnim()
    }

    buildAnim(){
        if(this.$title.length){
            this.timeline.fromTo(this.$title, 2, {alpha:0}, {alpha:1}, 'start');
        }
        if(this.$intro.length){
            this.timeline.fromTo(this.$intro, 2, {alpha:0}, {alpha:1}, 'start+=0.5');
        }
        if(this.$btn.length){
            this.timeline.fromTo(this.$btn, 2, {alpha:0}, {alpha:1}, 'start+=1');
        }
        new ScrollMagic.Scene({
            triggerElement:this.$wrapper[0],
            triggerHook:"onEnter"
        })
            .setTween(this.timeline)
            .addTo(this.controller);
    }

}