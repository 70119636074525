export default class MultipleContentAnim{

    constructor(element){

        this.$wrapper = element;
        this.$items = element.find('.js-multiple-content-item');


        this.index = 0;
        this.maxIndex = this.$items.length - 1;

        this.setWrapperHeight();

        this.interval = setInterval(
            this.incrementIndex.bind(this), 3000
        );
    }


    incrementIndex(){
        let prevIndex = this.index;
        this.index = this.index >= this.maxIndex ? 0 : this.index + 1;

        TweenMax.to(this.$items[prevIndex], 0.5, {y:'-15px', alpha:0});
        TweenMax.fromTo(this.$items[this.index], 0.5, {y:'15px', alpha:0}, {y:'0px', alpha:1});

    };



    setWrapperHeight(){
        let maxSize = 0;
        this.$items.each((n, item) => {
            let currentItemSize = $(item).outerHeight(true);
            maxSize = currentItemSize > maxSize ? currentItemSize : maxSize;
        });

        this.$wrapper.height(maxSize);
    }

};


