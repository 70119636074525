export default class financialToggle{

    constructor(element) {

        this.$wrapper = element;
        this.$tabs = this.$wrapper.find('.js-financial-toggle-tab');
        this.$items = this.$wrapper.find('.js-financial-toggle-item');


        this.bindEvents();
    }

    bindEvents(){
        this.$tabs.on('click', this.toggleTabs.bind(this));
    }

    toggleTabs(e){
        let $currentTarget = $(e.currentTarget);
        if(!$currentTarget.hasClass('is-active')){
            let currentId = $currentTarget.data('id');
            let $currentItem = this.$wrapper.find('.js-financial-toggle-item[data-id="'+currentId+'"]');
            let $otherItems = this.$items.not($currentItem);

            this.$tabs.not($currentTarget).removeClass('is-active');
            $currentTarget.addClass('is-active');
            TweenMax.fromTo($otherItems, 0.5, {y:"0%", alpha:1}, {y:"15px", alpha:0, onComplete: () => {
                    $otherItems.removeClass('is-active');
                    $currentItem.addClass('is-active');
                    TweenMax.fromTo($currentItem, 0.5, {y:"15px", alpha:0}, {y:"0ù", alpha:1});
                }
            });
        }
    }
}