import ScrollMagic from 'scrollmagic';

export default class Parallax{

    constructor(element){
        this.$wrapper = element;
        this.$wrap = this.$wrapper.find('.js-parallax-wrap');

        this.controller =  new ScrollMagic.Controller();
        this.duration = $(window).height() + this.$wrapper.innerHeight();
        this.scene = new ScrollMagic.Scene({triggerElement: this.$wrapper[0], duration:this.duration, triggerHook:1})
        .setTween(this.$wrap[0], {y: "-33.5%", ease: Linear.easeNone})
        .addTo(this.controller);
    }
}