import {TimelineMax} from 'gsap';
import ScrollMagic from "scrollmagic";

export default class NewsListAnim{

    constructor(element){

        this.$wrapper = element;
        this.$items = this.$wrapper.find('.news-list-item');

        this.controller = new ScrollMagic.Controller();
        this.timeline = new TimelineMax({pause:true});

        this.buildAnim()

    }

    buildAnim(){
        if(this.$items.length){
            this.timeline.staggerFromTo(this.$items, 2, {x:'-15px', alpha:0}, {x:"0px", alpha:1}, 0.2);
        }
        new ScrollMagic.Scene({
            triggerElement:this.$wrapper[0],
            triggerHook:"onEnter"
        })
            .setTween(this.timeline)
            .addTo(this.controller);
    }

}