$(document).ready(function() {
    //load slick
// Key Numbers
    if (document.querySelectorAll('.key-numbers-container').length > 0) {
        $('.key-numbers-container').slick({
            infinite: false,
            slidesToShow: 2.05,
            slidesToScroll: 2,
            dots: false,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1.5,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1.01,
                        slidesToScroll: 1,
                        swipeToSlide: true,
                    }
                }
            ],

        });
        $('.key-numbers-container').each(function () {
            setSlickController(this)
        })
    }
});
function setSlickController(keyNumbersContainer) {
    let keyName = $(keyNumbersContainer).data('name');
    let ketNumberController = $('.slide-controller[data-name='+ keyName + ']')
    var slidesToScroll = $(keyNumbersContainer).slick('slickGetOption', 'slidesToScroll');
    var totalSlides = $(keyNumbersContainer).slick('getSlick').slideCount;

    if($(keyNumbersContainer).slick('slickCurrentSlide') + slidesToScroll >= totalSlides) {
        $(ketNumberController).find('.slick-next').addClass('slick-disabled');
        $(ketNumberController).find('.slick-prev').addClass('slick-disabled');
    }
    $(keyNumbersContainer).on('afterChange', function(slick, currentSlide){
        if($(keyNumbersContainer).slick('slickCurrentSlide') + slidesToScroll >= totalSlides) {
            $(ketNumberController).find('.slick-next').addClass('slick-disabled');
        }
        else {
            $(ketNumberController).find('.slick-next').removeClass('slick-disabled');
        }
        if($(keyNumbersContainer).slick('slickCurrentSlide') == 0) {
            $(ketNumberController).find('.slick-prev').addClass('slick-disabled');
        }
        else {
            $(ketNumberController).find('.slick-prev').removeClass('slick-disabled');
        }
    });
    $(ketNumberController).find('.slick-prev').click(function(){
        $(keyNumbersContainer).slick('slickPrev');
    });
    $(ketNumberController).find('.slick-next').click(function(){
        $(keyNumbersContainer).slick('slickNext');
    });
}
