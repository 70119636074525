import {TweenMax} from 'gsap';
import ScrollMagic from 'scrollmagic';
export default class BackToTop{

    constructor(element){
        this.$btn = element;
        this.$grid = $('.grid');
        this.$btnReverse = $('.js-back-to-top-reverse');

        // this.controller = new ScrollMagic.Controller();
        this.wasScrolled = false;
        this.setPosition();
        this.bindEvents();
        // this.initScrollMagic();
    }

    bindEvents(){
        $(window).on('resize', this.handleResize.bind(this));
        this.$btnReverse.on('click keypress', this.goToTop.bind(this));
        this.$btn.on('click keypress', this.goToBottom.bind(this));
        $(window).on('scroll', this.handleScroll.bind(this));
    }


    handleResize(){
        clearTimeout(this.resizeTimer);
        this.resizeTimer = setTimeout(() => { 
            this.setPosition();
        }, 99)

    }

    handleScroll(){
        if($(window).scrollTop() > 0 & !this.wasScrolled){
            this.wasScrolled = true;
            TweenMax.to(this.$btn, 0.5, {alpha:0, onComplete: () => {
                this.$btn.hide();
            }})
        }
    }

    goToTop(e){
        if(e.type == 'keypress' && e.keyCode != 13){
            return false;
        }
        $("html,body").animate({ 
            scrollTop:0
        }, 1000);
    }

    goToBottom(e){
        if(e.type == 'keypress' && e.keyCode != 13){
            return false;
        }
        $("html,body").animate({ 
            scrollTop:500
        }, 1000);
    }

    // initScrollMagic(){
    //     let scene = new ScrollMagic.Scene({triggerHook: 0, duration: 1000})
    //     .setPin(this.$btn[0])
    //     // .addIndicators({name: "1 (duration: 1000)"}) // add indicators (requires plugin)
    //     .addTo(this.controller);
    // }

    setPosition(){
        let position;
        if (window.matchMedia("(max-width: 1520px)").matches){
            position = $(window).width() - 60;
        }
        else{
            position = this.$grid.offset().left  + this.$grid.outerWidth() - (this.$btn.outerWidth() / 2);
        }
        TweenMax.set(this.$btn, {'left':position}); 
    } 
}