import ScrollMagic from 'scrollmagic';
export default class EventPageIntro{

    constructor(element) {
        this.$wrapper = element;
        this.$cover = this.$wrapper.find('.js-event-page-intro-cover');
        this.$sticky = this.$wrapper.find('.js-event-page-intro-sticky');

        this.controller = new ScrollMagic.Controller();

        this.setCoverHeight();
        this.bindEvents();
    }

    bindEvents(){
        $(window).on('resize', this.setCoverHeight.bind(this));
        window.onload = () => {
            this.init();
        }
    }

    init(){
        this.scene = new ScrollMagic.Scene({
            triggerElement:this.$cover[0],
            triggerHook:0.1,
            duration:this.$wrapper.outerHeight() - this.$sticky.outerHeight()
        })
            .setPin(this.$sticky[0])
            .addTo(this.controller);
    }


    setCoverHeight(){
        let height = this.$sticky.outerHeight();
        this.$cover.css({'height':height});
    }


}