export default class SelectMultiple{

    constructor(element) {
        this.$wrapper = element;
        this.$placeholder = this.$wrapper.find('.js-select-mutiple-placeholder');
        this.$checkboxesWrapper = this.$wrapper.find('.js-select-multiple-checkboxes');
        this.$valsWrapper = this.$wrapper.find('.js-select-multiple-vals');
        this.$checkboxes = this.$checkboxesWrapper.find('input[type="checkbox"]');

        this.bindEvents();
    }


    bindEvents(){
        this.$checkboxes.on('change', (e) => {
            let $currentTarget = $(e.currentTarget);
            if($currentTarget.is(':checked')){
                this.appendToValsWrapper($currentTarget);
            }
            else{
                this.removeFromValsWrapper($currentTarget);
            }
        });
        this.$valsWrapper.on('click', '.remove', this.unCheck.bind(this));
        this.$placeholder.on('click', this.togglecheckboxesWrapper.bind(this));
    }

    togglecheckboxesWrapper(){
        this.$checkboxesWrapper.toggleClass('is-open');
    }

    appendToValsWrapper(input){
        let $htmlVal = this.getValTemplate(input)
        this.$valsWrapper.append($htmlVal);
        this.$checkboxesWrapper.removeClass('is-open');
    }

    removeFromValsWrapper(input){
        this.$valsWrapper.find('.js-select-multiple-val[data-id="'+input.attr('id')+'"]').remove();
    }

    unCheck(e){
        let $currentTarget = $(e.currentTarget);
        $currentTarget = $currentTarget.parent('.js-select-multiple-val');
        let id = $currentTarget.data('id');
        let $input = this.$checkboxes.filter('[id="'+id+'"]');
        $input.prop( "checked", false );
        $input.trigger('change');
    }

    getValTemplate(input){
        let val = input.val();
        let id = input.attr('id');

        let html = `<div class="js-select-multiple-val" data-id="${id}"><span class="remove"></span><span class="text">${id}</span></div>`;

        return $(html);
    }

}