import 'Hinclude/hinclude'

import './js/base'
import './js/flocker'

import chartSwitcher from './js/modules/chart-switcher'

import * as ScrollMagic from 'scrollmagic';
import {TimelineMax, TweenMax} from 'gsap';
import {ScrollMagicPluginGsap} from 'scrollmagic-plugin-gsap';

import './js/modules/carousels'
// add animation.gsap.js plugin to ScrollMagic
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

global.chartSwitcher = chartSwitcher;
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;

import 'slick-carousel'
