import {TimelineMax} from 'gsap';
export default class HeaderCoverAnim{

    constructor(element){

        this.$wrapper = element;
        this.timeline = new TimelineMax({paused:true});

        this.buildTimeline();
        this.timeline.play();
    }

    buildTimeline(){

        let $title = this.$wrapper.find('.js-header-cover-anim-title');
        let $subTitle = this.$wrapper.find('.js-header-cover-anim-subtitle');
        let $links =  this.$wrapper.find('.js-header-cover-anim-link a');
        if($title.length){
            this.timeline.fromTo($title, 0.5, {alpha:0}, {alpha:1}, 'start+=0.5');
        }
        if($subTitle.length){
            this.timeline.fromTo($subTitle, 1, {x:'-40px', alpha:0}, {x:'0', alpha: 1}, 'start');
        }
        if($links.length){
            this.timeline.staggerFromTo($links, 0.7, {alpha:0}, {alpha:1}, 0.1);
        }
    }

}