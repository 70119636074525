import ScrollMagic from 'scrollmagic';

export default class Slideshow{

    constructor(element){
        this.$wrapper = element;
        this.$images = this.$wrapper.find('.js-slideshow-image');
        this.$bubbles = this.$wrapper.find('.js-slideshow-bubble');
        this.$bullets = this.$wrapper.find('.js-slideshow-bullet');
        this.$bulletsWrap = this.$wrapper.find('.js-slideshow-bullet-wrap');
        this.$full = this.$wrapper.find('.js-slideshow-full');
        this.$bubblesMobile = this.$wrapper.find('.js-slideshow-bubble-mobile');

        this.baseTime = 3;
        this.totalTime = this.baseTime * this.$bullets.length;
        this.controller = new ScrollMagic.Controller();

        this.setPositions();
        this.buildTimeline();
        this.bindEvents();
    }

    bindEvents(){
        this.$bullets.on('mouseover', this.showBubble.bind(this));
        this.$bullets.on('mouseleave', this.hideBubble.bind(this));
        this.$bullets.on('click', this.goToLabel.bind(this));
        this.$bulletsWrap.on('mouseleave', this.playTimeline.bind(this));
    }

    playTimeline(){
        this.timeline.play();
    }


    showBubble(e){
        let $currentElement = $(e.currentTarget);
        let currentIndex = this.$bullets.index($currentElement);
        $(this.$bubbles[currentIndex]).addClass('is-hovered');
    }

    hideBubble(e){
        let $currentElement = $(e.currentTarget);
        let currentIndex = this.$bullets.index($currentElement);
        $(this.$bubbles[currentIndex]).removeClass('is-hovered');
    }

    goToLabel(e){
        let $currentElement = $(e.currentTarget);
        let currentIndex = this.$bullets.index($currentElement);
        if(currentIndex == 0){
            this.timeline.totalProgress(0);
            this.updateItems(0);
        }
        else if(currentIndex === this.$bullets.length - 1){
            this.timeline.totalProgress(1);
            this.updateItems( this.$bullets.length - 1);
        }
        else{
            this.timeline.play('label'+(currentIndex+1));
            this.updateItems(currentIndex);
        }

        this.timeline.pause();
    }

    setPositions(){
        this.$bullets.each((n,item) => {
            let position = (n / this.$bullets.length) * 100;
            TweenMax.set($(item), {'left':position+'%'});
            TweenMax.set(this.$bubbles[n], {'left':position+'%'})
        });
    }

    updateItems(n){
        $(this.$bullets[n]).addClass('is-active');
        this.$bullets.each((i,item) => {
           if(i > n){
               $(item).removeClass('is-active');
           }
        });

        this.$bubbles.not($(this.$bubbles[n])).removeClass('is-active');
        $(this.$bubbles[n]).addClass('is-active');

        this.$bubblesMobile.not($(this.$bubblesMobile[n])).removeClass('is-active');
        $(this.$bubblesMobile[n]).addClass('is-active');

        this.$images.not($(this.$images[n])).removeClass('is-active');
        $(this.$images[n]).addClass('is-active');
    }

    buildTimeline(){
        this.timeline = new TimelineMax();
        this.$bullets.each((n,item) => {
            this.timeline.to(this.$full, this.baseTime, {scaleX : n / this.$bullets.length, ease:Linear.easeNone,
                onComplete: () => {
                    this.updateItems(n)
                }
            },'label'+n);
        });
        this.timeline.to(this.$full, this.baseTime, {scaleX:1, ease:Linear.easeNone});

        this.scene = new ScrollMagic.Scene({
            triggerElement: this.$wrapper[0],
            reverse:false
        })
            .setTween(this.timeline)
            .addTo(this.controller);
    }
 
}