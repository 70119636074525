export default class PushBlock {
    constructor(element) {
        console.log(element)
        $('.push-block-container').on('click', this.bindEvents(e));
    }

    bindEvents(e){
        console.log(e);

    }
}