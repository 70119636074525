export default class AccountSwitch{

    constructor(element) {
        this.$wrapper = element;
        this.$btn = this.$wrapper.find('.js-account-switch-btn');

        this.bindEvents();
    }

    bindEvents(){
        this.$btn.on('click', this.toggleMenu.bind(this));
        $('body').on('click', this.hideMenu.bind(this));
    }

    toggleMenu(){
       this.$wrapper.toggleClass('is-open');
    }


    hideMenu(e){
        let $currentTarget = $(e.target);
        if($currentTarget.parents('.js-account-switch').length == 0){
            this.$wrapper.removeClass('is-open');
        }
    }


}