export default class TwitterCarousel {

    constructor(element) {
        this.$wrapper = element;
        this.$slider = this.$wrapper.find('.js-twitter-carousel-siema')
        this.$left = this.$wrapper.find('.js-twitter-carousel-left');
        this.$right = this.$wrapper.find('.js-twitter-carousel-right');
        this.init();
        this.bindEvents();

    }

    bindEvents(){
        this.$left.on('click', () => {
           this.slider.prev();
        });

        this.$right.on('click', () => {
            this.slider.next();
        });
    }

    init(){
        this.slider = new Siema({
            selector: this.$slider[0],
            duration: 200,
            easing: 'ease-out',
            perPage:{
                421: 1,
                769: 2,
                1025: 3
            },
            startIndex: 0,
            draggable: true,
            multipleDrag: true,
            threshold: 20,
        });
    }


}