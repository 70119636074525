export default class InputCloner {

    constructor(element) {
        this.$wrapper = element;
        this.$original = this.$wrapper.find('.js-input-cloner-original');
        this.$add = this.$wrapper.find('.js-input-cloner-tools .add');
        this.$remove = this.$wrapper.find('.js-input-cloner-tools .remove');

        this.max = this.$wrapper.data('max') ? this.$wrapper.data('max') : 9999;
        this.currMax = 0;

        this.bindEvents();
    }

    bindEvents(){
        this.$add.on('click', this.addClone.bind(this));
        this.$remove.on('click', this.removeClone.bind(this));
    }

    addClone(){
        if(this.currMax < this.max){
            this.$remove.show();
            this.$original.clone().appendTo(this.$wrapper).removeClass('js-input-clone-original').addClass('js-input-clone-clone');
            this.currMax += 1;
        }
        if(this.currMax === this.max - 1){
            this.$add.hide();
        }
    }

    removeClone(){
        this.$wrapper.find('.js-input-clone-clone:last-child').remove();
        this.$add.show();
        if(this.$wrapper.find('.js-input-clone-clone').length == 0){
            this.$remove.hide();
        }
        this.currMax -= 1;

    }

}