export default class Doughnut{

    constructor(element){

        this.$wrapper = element;
        this.$canvas = this.$wrapper.find('.js-doughnut-chart');
        this.$dataUri = this.$wrapper.data('json');
        this.$templateWrapper = this.$wrapper.find('.js-doughnut-list');
        this.$tooltipsWrapper = this.$wrapper.find('.js-doughnut-tooltips-wrapper');

        this.chartOptions = {
            responsive: true,
            cutoutPercentage: 70,
            tooltips:{
                enabled:false
            }, 
            legend:{
                display:false
            },
            hover: {
                mode: 'nearest',
                intersect: false,
                onHover: (e, item) => { 
                    if (item.length) {
                        let segment = item[0];
                        let segmentIndex = segment['_index'];
                        this.updateColors(segmentIndex);
                    }
                }
            }
        }
        this.init();
    }

    
    init(){
        jQuery.getJSON(this.$dataUri, (data) => {
            this.$data = data;
            this.initChart();

            this.renderList();
            this.$listItem = this.$wrapper.find('.js-doughnut-list-item');

            this.renderTooltips();
            this.$tooltips = this.$wrapper.find('.js-doughnut-tooltips-wrapper-item'); 

            this.$listItem.on('mouseenter', (e) => {
                this.updateColors($(e.currentTarget).index());
            });
            this.$listItem.on('mouseleave', this.resetColors.bind(this));
        });

        this.$canvas.on('mouseleave', this.resetColors.bind(this));
    }

    initChart(){
        this.chart = new Chart(this.$canvas[0], {
            type: 'doughnut',
            data: this.$data,
            options : this.chartOptions
        });
    }

    updateColors(n){
        for(let i = 0; i < this.chart.data.datasets[0].backgroundColor.length; i++){
            if(i != n){ 
                this.chart.data.datasets[0].backgroundColor[i] = this.chart.data.datasets[0].backgroundColor[i].replace(/[\d\.]+\)$/g, "0.25)");
                $(this.$listItem[i]).addClass('is-inactive');
                $(this.$tooltips[i]).removeClass('is-active');
            }
            else{
                this.chart.data.datasets[0].backgroundColor[i] = this.chart.data.datasets[0].backgroundColor[i].replace(/[\d\.]+\)$/g, "1)");
                $(this.$listItem[i]).removeClass('is-inactive');
                $(this.$tooltips[i]).addClass('is-active');
            }
        }
        this.chart.update();
    }

    resetColors(){
        for(let i = 0; i < this.chart.data.datasets[0].backgroundColor.length; i++){
            this.chart.data.datasets[0].backgroundColor[i] = this.chart.data.datasets[0].backgroundColor[i].replace(/[\d\.]+\)$/g, "1)");
        }
        this.$listItem.removeClass('is-inactive');
        this.$tooltips.removeClass('is-active');
        this.chart.update();
    }


    renderList(){
        let $template = "";
        for(let i = 0; i < this.$data.labels.length; i++){
            let currentLabel = this.$data.labels[i];
            let currentData = this.$data.datasets[0].data[i];
            let currentSubhead = this.$data.subheads[i];
            let currentColor = this.$data.datasets[0].backgroundColor[i];

            let currentLine = 
            `<li class="doughnut-list-item js-doughnut-list-item">
                <div class="doughnut-list-item-left" style="color:${currentColor}">${currentData}%</div>
                <div class="doughnut-list-item-right">
                   <p class="doughnut-list-item-right-title">${currentLabel}</p> 
                   <p class="doughnut-list-item-right-subhead">${currentSubhead}</p> 
                </div>
            </li>\n`;
 
            $template += currentLine;
        }
        this.$templateWrapper.append($template);
    }
    

    renderTooltips(){
        let $template = "";
        for(let i = 0; i < this.$data.labels.length; i++){
            let currentLabel = this.$data.labels[i];
            let currentData = this.$data.datasets[0].data[i];
            let currentColor = this.$data.datasets[0].backgroundColor[i];

            let currentTooltip = 
            `<div class="doughnut-tooltips-wrapper-item js-doughnut-tooltips-wrapper-item">
                <p class="doughnut-tooltips-wrapper-item-data" style="color:${currentColor}">${currentData}%</p>
                <p class="doughnut-tooltips-wrapper-item-label">${currentLabel}</p>
            </div>\n`;

            $template += currentTooltip; 
        }

        this.$tooltipsWrapper.append($template);
    }
 
}
