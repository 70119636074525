export default class BarHorizontal{

    constructor(element){

        this.$wrapper = element;
        this.$canvas = this.$wrapper.find('.js-bar-horizontal-chart');
        this.$dataUri = this.$wrapper.data('json');


        this.init();
    }
    

    init(){
        jQuery.getJSON(this.$dataUri, (data) => { 
            this.$data = data;
            this.chartOptions = {
                responsive:true,   
                events: ['touchstart', 'mousemove'],   
                tooltips: {
                    callbacks: {
                        title: (tooltipItem, data) => {
                            let value =  tooltipItem[0].xLabel;
                            return value;
                        },
                        label: (tooltipItem, data) => {
                            return tooltipItem.yLabel;
                        },
                        labelTextColor: function(tooltipItem, chart) {
                            return 'rgba(255,255,255,0.5)';
                        }

                    },
                    displayColors:false,
                },
                legend:{
                    display:false
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            min: this.$data.minVal
                        }
                    }],
                    yAxes: [{
                        barPercentage: this.isMobile() ? 0.3 : 0.07,
                        gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                        }
                    }]
                }
            }
            this.initChart();
        });
    }

    initChart(){
        this.chart = new Chart(this.$canvas[0], {
            type: 'horizontalBar',
            data: this.$data,
            options:this.chartOptions
        });
    }

    isMobile(){
        return window.matchMedia("(max-width: 800px)").matches
    }

}