export default class KeyfiguresMosaic{

    constructor(element) {
        this.$wrapper = element;
        this.$triggers = this.$wrapper.find('.js-keyfigures-mosaic-trigger');
        this.$triggersPanel = this.$wrapper.find('.js-keyfigures-mosaic-trigger-panel');
        this.$items = this.$wrapper.find('.js-keyfigures-mosaic-item');
        this.$panels = this.$wrapper.find('.js-keyfigures-mosaic-panel');
        this.$closes = this.$wrapper.find('.js-keyfigures-mosaic-panel-close');

        this.bindEvents();
    }

    bindEvents(){
        this.$triggersPanel.each((n,item) => {
            $(item).on('click', this.onClickTriggerPanels.bind(this,n));
        });
        this.$triggers.each((n,item) => {
            $(item).on('click', this.onClickTrigger.bind(this,n));
        });
        this.$closes.on('click', this.onClickClose.bind(this));
    }

    onClickTriggerPanels(n,e){
        let $currentPanel = $(this.$panels[n]);
        $currentPanel.addClass('is-active');
    }

    onClickTrigger(n,e){
        let $currentTarget = $(e.currentTarget);
        let $currentItem = $(this.$items[n]);
        let $oldItem = this.$wrapper.find('.js-keyfigures-mosaic-item.is-active');
        if(!$currentItem.hasClass('is-active')){
            this.$triggers.removeClass('is-active');
            TweenMax.fromTo($oldItem, 0.3, {y:'0%', alpha:1}, {y:'50px' , alpha:0, onComplete: () => {
                    this.$items.removeClass('is-active');
                    $currentTarget.addClass('is-active');
                    $currentItem.addClass('is-active');
                    TweenMax.fromTo($currentItem, 0.3, {y:'50px', alpha:0}, {y:'0%' , alpha:1});
                }
            });
        }
    }

    onClickClose(e){
        this.$panels.removeClass('is-active');
    }

}