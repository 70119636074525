import lightGallery from 'lightgallery';
export default class Gallery {

    constructor(element) {
        this.$wrapper = element;
        this.init();
    }

    init(){
        lightGallery(this.$wrapper[0]);
    }
}