// Import jQuery
import InputCloner from "./modules/input-cloner";

// Require modules.
var grid = require('./modules/grid.js');
import Gifffer from 'gifffer';
import 'hammerjs';

import HeaderCoverAnim from './modules/header-cover-anim';
import AchievementAnim from './modules/achievement-anim';
import InnovationAnim from './modules/innovation-anim';
import NewsListAnim from './modules/news-list-anim';
import MultipleContentAnim from './modules/multiple-content-anim';
import Doughnut from "./modules/doughnut";
import BarVertical from "./modules/bar-vertical";
import BarHorizontal from "./modules/bar-horizontal";
import BackToTop from "./modules/back-to-top";
import ProjectImgsAnim from "./modules/project-imgs-anim";
import Slideshow from "./modules/slideshow";
import Parallax from "./modules/parallax";
import PageIntroAnim from "./modules/page-intro-anim";
import financialToggle from "./modules/financial-toggle";
import chartSwitcher from "./modules/chart-switcher";
import Select from "./modules/select";
import JobboardFixed from "./modules/jobboard-fixed";
import AccountSwitch from "./modules/account-switch";
import UploadInput from "./modules/upload-input";
import InputDate from "./modules/input-date";
import InputPhone from "./modules/input-phone";
import SelectMultiple from "./modules/select-multiple";
import CountDown from "./modules/countdown";
import EventPageIntro from "./modules/event-page-intro";
import Gallery from "./modules/gallery";
import TwitterCarousel from "./modules/twitter-carousel";
import CarouselMobile from "./modules/carousel-mobile";
import KeyfiguresImgSlider from "./modules/keyfigures-img-slider";
import KeyfiguresMosaic from "./modules/keyfigures-mosaic";
import InteractiveMap from "./modules/interactive-map";
import CoverAlt from "./modules/cover-alt";
import Flocker from "./modules/flocker";
import PushBlock from "./modules/push-block";

var breakpoint = {
  mobile: 420,
  tablet: 769
};

(function () {
  if ( typeof NodeList.prototype.forEach === "function" ) return false;
  NodeList.prototype.forEach = Array.prototype.forEach;
})();

$(function(){

  const gifs = Gifffer();
  setTimeout(() => {
    gifs.forEach((item,n) => {
      item.click();
    });
  },1000)

  $('.js-focusable').on('keypress', (e) => {
    let keycode = event.keyCode || event.which;
    if(keycode == 13) {
      $(e.currentTarget).click();
    }
  })

    //Back to top
    if($('.js-back-to-top').length){
      $('.js-back-to-top').each((n,item) => {
        new BackToTop($(item));
      });
    }

  //Jobboard progress bar
  if($('.js-jobboard-fixed').length){
    $('.js-jobboard-fixed').each((n,item) => {
      new JobboardFixed($(item));
    });
  }

  // Cover Alt
  if($('.js-cover-alt').length){
    $('.js-cover-alt').each((n,item) => {
      new CoverAlt($(item));
    });
  }

  //Upload Input
  if($('.js-upload-input').length){
    $('.js-upload-input').each((n,item) => {
      new UploadInput($(item));
    });
  }

  //Mosaic keyfigures
  if($('.js-keyfigures-mosaic').length){
    $('.js-keyfigures-mosaic').each((n,item) => {
      new KeyfiguresMosaic($(item));
    });
  }

  //Keyfigures Img
  if($('.js-keyfigures-img-slider').length){
    $('.js-keyfigures-img-slider').each((n,item) => {
      new KeyfiguresImgSlider($(item));
    });
  }

  //Interactive map
  if($('.js-interactive-map').length){
    $('.js-interactive-map').each((n,item) => {
      new InteractiveMap($(item));
    });
  }

  //Gallery
  if($('.js-gallery').length){
    $('.js-gallery').each((n,item) => {
      new Gallery($(item));
    });
  }

  //Flocker
  if($('.js-flocker').length){
    $('.js-flocker').each((n,item) => {
      new Flocker($(item));
    });
  }

  //PushBlock
  if($('.js-push-block').length){
    $('.js-push-block').each((n,item) => {
      new PushBlock($(item));
    });
  }

  //Date Input
  if($('.js-input-date').length){
    $('.js-input-date').each((n,item) => {
      new InputDate($(item));
    });
  }

  //input clone
  if($('.js-input-cloner').length){
    $('.js-input-cloner').each((n,item) => {
      new InputCloner($(item));
    });
  }

  //Phone Input
  if($('.js-input-phone').length){
    $('.js-input-phone').each((n,item) => {
      new InputPhone($(item));
    });
  }

  //Select Multiple
  if($('.js-select-multiple').length){
    $('.js-select-multiple').each((n,item) => {
      new SelectMultiple($(item));
    });
  }

  //Carousel Mobile
  if($('.js-carousel-mobile').length){
    $('.js-carousel-mobile').each((n,item) => {
      let startAt = $(item).data('start-at') ? $(item).data('start-at') : 768;
      new CarouselMobile(item, {
        stickToRight:false,
        startAt:startAt
      });
    });
  }

  //Btn account
    if($('.js-account-switch').length){
      $('.js-account-switch').each((n,item) => {
        new AccountSwitch($(item));
      });
    }

    // Page intro anim
    if($('.js-page-intro-anim').length){
      $('.js-page-intro-anim').each((n,item) => {
        new PageIntroAnim($(item));
      });
    }

    // Event page intro
    if($('.js-event-page-intro').length){
      $('.js-event-page-intro').each((n,item) => {
        new EventPageIntro($(item));
      });
    }

    // FinancialTab
    if($('.js-financial-toggle').length){
      $('.js-financial-toggle').each((n,item) => {
        new financialToggle($(item));
      });
    }

    //Twitter carousel
    if($('.js-twitter-carousel').length){
      $('.js-twitter-carousel').each((n,item) => {
        new TwitterCarousel($(item));
      });
    }

    // Parallax
    if($('.js-parallax').length){
      $('.js-parallax').each((n,item) => {
        new Parallax($(item));
      });
    }

    // Slideshow
    if($('.js-slideshow').length){
      $('.js-slideshow').each((n,item) => {
        new Slideshow($(item));
      });
    }

    //Bar vertical
    if($('.js-bar-vertical').length){
      $('.js-bar-vertical').each((n,item) => {
        new BarVertical($(item)); 
      });
    }

    // Project imgs anim
    if($('.js-project-imgs-anim').length){
      $('.js-project-imgs-anim').each((n,item) => {
        new ProjectImgsAnim($(item)); 
      });
    }

    //Bar horizontal
    if($('.js-bar-horizontal').length){
      $('.js-bar-horizontal').each((n,item) => {
        new BarHorizontal($(item)); 
      });
    }

    //Countdown
    if($('.js-countdown').length){
      $('.js-countdown').each((n,item) => {
        new CountDown($(item));
      });
    }

    //Doughnut
    if($('.js-doughnut').length){
      $('.js-doughnut').each((n,item) => {
        new Doughnut($(item)); 
      });
    }

    // News list anim
    if($('.news-list').length){
      $('.news-list').each((n , item) => {
        new NewsListAnim($(item));
      })
    }

    // Multiple content anim
    if($('.js-multiple-content').length){
      $('.js-multiple-content').each((n , item) => {
        new MultipleContentAnim($(item));
      });
    }

    // Achievement block anim
    if($('.achievements-grid .row').length){
      $('.achievements-grid .row').each((n , item) => {
        new AchievementAnim($(item));
      })
    }

    // Innovation anim
    if($('.innovation-container').length){
      $('.innovation-container').each((n , item) => {
        new InnovationAnim($(item));
      });
    }

    //Header cover anim
    if($('.js-header-cover-anim').length){
      $('.js-header-cover-anim').each(function(){
          new HeaderCoverAnim($(this));
      });
    }
    // Grid.
    if ($('.js-dev-grid').length) {
        grid.init();
    }

    // Grid.
    if ($('.js-dev-grid').length) {
      grid.init();
    }

    // Chart Switcher
    if($('.js-chart-switcher').length){
      $('.js-chart-switcher').each((n,item) => {
        new chartSwitcher($(item));
      });
    }

    // Select
    if($('.js-select').length){
      $('.js-select').each((n,item) => {
        new Select($(item));
      });
    }

    // Cookies warning
    $('#cookies-warning-close').on('click', function(e){
      e.preventDefault();
      $('.cookies-warning').fadeOut();
    });

    // nav
    $('.header-menu-btn').on('click', function(e){
      $('body').toggleClass('show-nav');
      if($('body').hasClass('show-nav')){
        trapFocus($('.main-nav')[0]);
      }
      else{
      }
    });

    $('.main-nav-list > li > a').on('click', function(e){
      e.preventDefault();
      var listItem = $(this).closest('li');
      var listItemIndex = listItem.index();

      if(!listItem.hasClass('active')){
        $('.main-nav-list > li').removeClass('active').eq(listItemIndex).addClass('active');
        $('.main-nav-visuals li').removeClass('active').eq(listItemIndex).addClass('active');

        if (window.matchMedia("(max-width: "+breakpoint.tablet+"px)").matches) {
          $('.main-nav-list > li ul').stop(true, true).slideUp();
          $('.main-nav-list > li').eq(listItemIndex).find('ul').stop(true, true).slideDown();
        }
      } else {
        $('.main-nav-list > li').removeClass('active');
        $('.main-nav-visuals li').removeClass('active');

        if (window.matchMedia("(max-width: "+breakpoint.tablet+"px)").matches) {
          $('.main-nav-list > li ul').stop(true, true).slideUp();
        }
      }
    });

    $(window).on('resize', function(){
      if (window.matchMedia("(min-width: "+breakpoint.tablet+"px)").matches) {
        $('.main-nav-list > li ul').stop(true, true).show();
      } else {
        $('.main-nav-list > li ul').stop(true, true).hide();
      }
    });

    // search
    $('.header-search-btn').on('click', function(e){
      $('body').toggleClass('show-search');
    });

    $('.search-input').on('input focus', function(e){
      var value = $(this).val();
      if(value.length > 3){
        var ctn = $(this).closest('.search-container');
        ctn.addClass('focus');
      }
    }).on('input', function(){
      var value = $(this).val();
      if(value.length > 3){
        var ctn = $(this).closest('.search-container');
        ctn.find('.search-submit').removeClass('button-disabled');
        // ## Custom ICADE ## //
        var url = window.location.pathname;
        var urlsParts = url.split('/');
        if(urlsParts.length > 0 && urlsParts[1] == 'en'){
          var apiUrl = "/en/search/suggest";
        } else {
          var apiUrl = "/search/suggest";
        }
        $.ajax({
          url: apiUrl,
          data: { 'query': value }
        }).done(function (data) {
          // insert data into div
          $("#suggestResulst").html(data);
        });
      } else {
        var ctn = $(this).closest('.search-container');
        ctn.find('.search-submit').addClass('button-disabled');
      }
    }).on('blur', function(e){
      var ctn = $(this).closest('.search-container');
      ctn.removeClass('focus');
    });
  if($(window).width() <= 800) {
    $('.js-footer-accordion-header').on('click', function() {
      var target = $(this).data('target');
      var $targetElement = $('.'+target);
      //accordion this
        $(this).toggleClass('active');
        $targetElement.toggleClass('inactive');
    })
  };

    //a 768px et en dessous, cliquer sur side-anchors ul add visible to all li
  if($(window).width() <= 800) {
    $('.side-anchors ul').on('click', function() {
      $('.side-anchors ul li').toggleClass('visible');
    })
  }
    var scrollPos = {
      yPos: undefined,
      xPos: undefined,

      init: function(){
        var self = this;

        self.supportPageOffset = window.pageXOffset !== undefined;
        self.isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

        self.scrollCheck();

        window.addEventListener('scroll', function() {
          self.scrollCheck();
        });
      },
      scrollCheck: function(){
        var isAnyInView = false;
        $('.side-anchors a').each(function() {
          var target = $(this).data('target');
          var $targetElement = $('#'+target);
          if ($targetElement.length) {
            // Get the target element's position
            var elementTop = $targetElement.offset().top;
            var elementBottom = elementTop + $targetElement.outerHeight();

            // Get the current viewport's top and bottom positions
            var viewportTop = $(window).scrollTop();
            var viewportBottom = viewportTop + $(window).height();
            // Check if the target element is in the viewport
            if (elementBottom > viewportTop && elementTop < viewportBottom) {
              isAnyInView = true;
            }
          }
        });
        $('.side-anchors a').each(function() {
          // Get the data-target attribute value
          var target = $(this).data('target');
          var $targetElement = $('#'+target);
          if ($targetElement.length) {
            // Get the target element's position
            var elementTop = $targetElement.offset().top;
            var elementBottom = elementTop + $targetElement.outerHeight();

            // Get the current viewport's top and bottom positions
            var viewportTop = $(window).scrollTop();
            var viewportBottom = viewportTop + $(window).height();
            // Check if the target element is in the viewport
            if ((elementBottom - 150) > viewportTop && (elementTop - 150) < viewportBottom) {
              var scrollTop = $(window).scrollTop();
              var scrollHeight = $targetElement.prop('scrollHeight');
              var clientHeight = $targetElement.outerHeight();
              // Add 'active' class to the parent if the target is in the viewport
              $(this).parent().addClass('active');
              //remove active of other li
              $(this).parent().siblings().removeClass('active');
              //get the percent of progression of the element in the viewport
                var percent = (((scrollTop + 150) - elementTop) / (elementBottom - elementTop)) * 100;
                percent = percent > 100?100:percent;
                //change width of :after of $(this).parent() to the percent width 0 and 100 max
                $(this).parent().find('.side-anchors-timeline').css('width', percent + '%');
              return false;
            }
          }
        });
      }
    };
    scrollPos.init();
  $('.side-anchors a').on('click', function(event){
    //when clicked, slide up 60px
    // Empêche le comportement par défaut du navigateur
    event.preventDefault();

    //si on est en mobile et que le parent à la classe active, don't move
    if($(this).parent().hasClass('active') && $(window).width() <= 800) {
      return;
    }
    // Obtenir la cible de l'ancre
    var target = $(this.getAttribute('href'));

    if (target.length) {
      //get height of header and side-anchors
      var offsetMobile = $('.header').height() + $('.side-anchors').height();
      var offset = $(window).width() <= 800 ?offsetMobile:60;
      console.log(offset);
      // Animer le scroll vers la cible
      $('html, body').animate({
        //if desktop, scroll to top - 60px, if mobile, scroll to top - 150px
        scrollTop: target.offset().top - offset
      });
      //set the url to the anchor
        window.location.hash = this.getAttribute('href');
        
    }
  });
    // History progress bar
    var history = {
      progressBar: $('.history-list-wrapper .progress'),
      items: $('.history-list > li'),

      init: function(){
        var self = this;

        self.updateVariables();

        window.addEventListener('scroll', function() {
          self.updateProgress();
        });
        window.addEventListener('resize', function() {
          self.updateVariables();
          self.updateProgress();
        });
      },
      updateVariables: function(){
        var self = this;

        self.progressTop = self.progressBar.offset().top;
        self.viewHalf = Math.floor(parseInt($(window).height()) / 2);
      },
      updateProgress: function(){
        var self = this;

        var height = scrollPos.yPos + self.viewHalf - self.progressTop;
        self.progressBar.css({'height': height});

        var gap = 30;
        if (window.matchMedia("(min-width: " + breakpoint.tablet + "px)").matches) {
          gap = 50;
        }

        self.items.each(function(i,e){
          var itemTop = $(e).offset().top;
          if(scrollPos.yPos + self.viewHalf > itemTop + gap){
            $(e).addClass('active');
          } else {
            $(e).removeClass('active');
          }
        });
      }
    }
    if($('.history-list-wrapper').length > 0){
      history.init();
    }

    Siema.prototype.addPagination = function () {
      var self = this;
      if (self.selector.parentNode.querySelectorAll('.dots').length < 1) {
        setTimeout(function () {
          var ctn = document.createElement('div');
          ctn.className = "dots";

          var _loop = function _loop(i, label) {
            var btn = document.createElement('button');
            btn.className = "dot";
            btn.textContent = i;
            if(label){
              btn.setAttribute('aria-label', label);
            }
            btn.addEventListener('click', function () {
              return self.goTo(i);
            });
            ctn.appendChild(btn);
          };

          for (var i = 0; i < self.innerElements.length; i++) {
            let label = self.innerElements[i].getAttribute('data-aria-label-btn');
            _loop(i, label);
          }
          self.selector.parentNode.appendChild(ctn);
          self.selector.parentNode.querySelectorAll('.dot')[0].className = "dot active";
          self.selector.parentNode.querySelectorAll('.dot')[0].setAttribute('aria-selected', true);
        }, 100);
      }
    };

    var setActiveDot = function setActiveDot() {
      var slider = this;
      var elem = slider.selector.parentNode;
      var dots = elem.querySelectorAll('.dot');
      if (dots.length > 0) {
        dots.forEach(function (dot) {
          dot.setAttribute('aria-selected', false);
          dot.className = "dot";
        });
        var currentSlide = slider.currentSlide;
        if (currentSlide < 0) {
          currentSlide = slider.innerElements.length + currentSlide;
        }
        var currentDot = elem.querySelectorAll('.dot')[currentSlide];

        if (currentDot){
          currentDot.className = "dot active";
          currentDot.setAttribute('aria-selected', true);
        }
      }
    };

    var printSlideIndex = function printSlideIndex() {
      //document.querySelector('.js-index').innerHTML = this.currentSlide;

      this.innerElements.forEach((slide, i) => {
        const addOrRemove = i === this.currentSlide ? 'add' : 'remove';
        this.innerElements[i].classList[addOrRemove]('show');
      })
    }

    var toggleSlider = function toggleSlider(slider, breakpoint) {
      if (slider.isInit == undefined) {
        slider.isInit = true;
      }
      if (window.matchMedia("(min-width: " + breakpoint + "px)").matches) {
        if (slider.isInit == true) {
          slider.isInit = false;
          slider.destroy(true, function () {
            document.querySelectorAll('.' + slider.selector.className + ' .dots').forEach(function (e) {
              return e.parentNode.removeChild(e);
            });
          });
        }
      } else {
        if (slider.isInit == false) {
          slider.isInit = true;
          slider.init();
        }
      }
    };

    // Key Numbers
    if (document.querySelectorAll('.twitter-container').length > 0) {
      var keyNumbersSliders = $('.twitter-container');

      keyNumbersSliders.each(function(i,e) {
        var instance = new Siema({
          selector: e,
          duration: 400,
          easing: 'ease-out',
          perPage: 1,
          startIndex: 0,
          draggable: true,
          multipleDrag: true,
          threshold: 20,
          loop: false,
          rtl: false,
          onInit: function onInit() {
            this.addPagination();
          },
          onChange: setActiveDot
        });

        window.addEventListener('resize', function () {
          instance.addPagination();
        }, false);
      });

    }

    // Photos slider
    if (document.querySelectorAll('.photo-gallery-slider').length > 0) {
      var photoGallerySliders = $('.photo-gallery-slider');
      photoGallerySliders.each(function(i,e) {
        var instance = new Siema({
          selector: e,
          duration: 400,
          easing: 'ease-out',
          perPage: 1,
          startIndex: 0,
          draggable: true,
          multipleDrag: true,
          threshold: 20,
          loop: false,
          rtl: false,
          onInit: printSlideIndex,
          onChange: printSlideIndex
        });

        window.addEventListener('resize', function () {
          //instance.addPagination();
        }, false);

        $(e).siblings('.photo-gallery-prev').on('click', function(e){
          e.preventDefault();
          instance.prev();
        });
        $(e).siblings('.photo-gallery-next').on('click', function(e){
          e.preventDefault();
          instance.next();
        });
      });

    }

    // Media kit slider
    if (document.querySelectorAll('.media-kit-slider').length > 0) {
      var photoGallerySliders = $('.media-kit-slider');

      photoGallerySliders.each(function(i,e) {
        var instance = new Siema({
          selector: e,
          duration: 400,
          easing: 'ease-out',
          perPage: {
            0: 1,
            420: 2,
            769: 3
          },
          startIndex: 0,
          draggable: true,
          multipleDrag: true,
          threshold: 20,
          loop: false,
          rtl: false,
          // onInit: printSlideIndex,
          // onChange: printSlideIndex
        });

        var ctn = $(e).closest('.media-kit');
        ctn.find('.media-kit-slider-prev').on('click', function(e){
          e.preventDefault();
          instance.prev();
        });
        ctn.find('.media-kit-slider-next').on('click', function(e){
          e.preventDefault();
          instance.next();
        });
      });

    }


    // News slider
    if(document.querySelectorAll('.news-list').length > 0){
      var newsSlider = new Siema({
        selector: '.news-list',
        duration: 400,
        easing: 'ease-out',
        perPage: {
          0: 1,
          420: 2,
        },
        startIndex: 0,
        draggable: true,
        multipleDrag: true,
        threshold: 20,
        loop: false,
        rtl: false,
        onInit: () => {},
        onChange: () => {},
      });

      window.addEventListener('resize', function(){
        toggleSlider(newsSlider, breakpoint.tablet);
      }, false);

      setTimeout(function(){
        toggleSlider(newsSlider, breakpoint.tablet);
      }, 0);
    }

    // Proof slider
    const proofSlider = function (element) {
      const self = this;

      self.ctn = $(element);
      self.active = 1;
      self.id = 'slider_' + Math.random().toString(36).substr(2, 9);
      self.hammer = self.ctn.length ? new Hammer(self.ctn[0]) : false;

      self.visuals = self.ctn.find('.proof-slider-visual');
      self.content = self.ctn.find('.proof-slider-slides');
      self.prev = self.ctn.find('.proof-slider-nav .prev');
      self.next = self.ctn.find('.proof-slider-nav .next');
      self.dots = self.ctn.find('.proof-slider-nav .dots');

      self.max = self.content.children().length;

      self.updateNavigationButtons();

      self.next.on('click', function (e) {
        e.preventDefault();
        if (self.active < self.max) {
          self.goTo(self.active + 1);
            } else {
                self.goTo(1);
            }
            self.updateNavigationButtons();
        });

        self.prev.on('click', function (e) {
          e.preventDefault();
          if (self.active > 1) {
            self.goTo(self.active - 1);
          } else {
            self.goTo(self.max);
          }

          self.updateNavigationButtons();
        });

        self.dots.find('.dot').on('click', function (e) {
          e.preventDefault();
          let target = $(this).index();
          self.goTo(target + 1);
          self.updateNavigationButtons();
        });

        if (window.matchMedia("(max-width: 420px)").matches) {
          self.hammer.on('swipeleft', function (e) {
            self.next.click();
          });
          self.hammer.on('swiperight', function (e) {
            self.prev.click();
          });
        }
    };

    proofSlider.prototype.goTo = function(target) {
        let self = this;

        self.visuals.children('div.active').removeClass('active');
        self.visuals.children('div').eq(target - 1).addClass('active');

        self.content.children('div.active').stop(true, true).slideUp(400).removeClass('active');

        self.content.children('div').eq(target - 1).stop(true, true).slideDown(400).addClass('active');

        self.dots.find('.dot').removeClass('active').eq(target - 1).addClass('active');
        self.active = target;
    };

    proofSlider.prototype.updateNavigationButtons = function() {
      let self = this;


      if (self.active == 1) {
        self.prev.addClass('disabled');
      } else {
        self.prev.removeClass('disabled');
      }

      if (self.active == self.max) {
        self.next.addClass('disabled');
      } else {
        self.next.removeClass('disabled');
      }
    };

    $(document).ready(function() {
      $('.proof-slider').each(function() {
        new proofSlider(this);
      });
    });

    // Questions slider
    const questionsSlider = function (element) {
        const self = this;

        self.ctn = $(element);
        self.active = 1;
        self.id = 'slider_' + Math.random().toString(36).substr(2, 9); // Crée un ID unique pour chaque instance
        self.hammer = self.ctn.length ? new Hammer(self.ctn[0]) : false;

        self.visuals = self.ctn.find('.questions-slider-visual');
        self.content = self.ctn.find('.questions-slider-slides');
        self.prev = self.ctn.find('.questions-slider-nav .prev');
        self.next = self.ctn.find('.questions-slider-nav .next');
        self.dots = self.ctn.find('.questions-slider-nav .dots');

        self.max = self.content.children().length;

        self.updateNavigationButtons();

        self.next.on('click', function (e) {
          e.preventDefault();
          if (self.active < self.max) {
            self.goTo(self.active + 1);
          }
          self.updateNavigationButtons();
        });

        self.prev.on('click', function (e) {
            e.preventDefault();
            if (self.active > 1) {
                self.goTo(self.active - 1);
            }
            self.updateNavigationButtons();
        });

        self.dots.find('.dot').on('click', function (e) {
            e.preventDefault();
            var target = $(this).index();
            self.goTo(target + 1);
            self.updateNavigationButtons();
        });

        self.ctn.on('click', '.questions-slider-show-answer', function (e) {
            e.preventDefault();
            self.showAnswer(this);
        });

        self.ctn.on('click', '.questions-slider-answer-close', function (e) {
            e.preventDefault();
            self.hideAnswers();
        });

        if (window.matchMedia("(max-width: 420px)").matches) {
            self.hammer.on('swipeleft', function (e) {
                self.next.click();
            });
            self.hammer.on('swiperight', function (e) {
                self.prev.click();
            });
        }
    };
    questionsSlider.prototype.goTo = function(target) {
        var self = this;

        self.hideAnswers();

        self.visuals.children('div.active').removeClass('active');
        self.visuals.children('div').eq(target - 1).addClass('active');

        self.content.children('div.active').stop(true, true).slideUp(400).removeClass('active');
        self.content.children('div').eq(target - 1).stop(true, true).slideDown(400).addClass('active');

        self.dots.find('.dot').removeClass('active').eq(target - 1).addClass('active');
        self.active = target;
    };

    questionsSlider.prototype.showAnswer = function(el) {
        var self = this;
        var num = $(el).parent().index();
        self.ctn.find('.questions-slider-answer').eq(num).show();
        self.ctn.find('.questions-slider-answers').fadeIn();
    };

    questionsSlider.prototype.hideAnswers = function() {
        var self = this;
        self.ctn.find('.questions-slider-answers').fadeOut(300, function() {
            self.ctn.find('.questions-slider-answer').hide();
        });
    };

    questionsSlider.prototype.updateNavigationButtons = function() {
      var self = this;

      if (self.active === 1) {
        self.prev.addClass('disabled');
      } else {
        self.prev.removeClass('disabled');
      }

      if (self.active === self.max) {
        self.next.addClass('disabled');
      } else {
         self.next.removeClass('disabled');
      }
    };

    $(document).ready(function() {
        $('.questions-slider').each(function() {
            new questionsSlider(this);
        });
    });

    // team
    $('.team-list a').on('click', function(e){
      e.preventDefault();
      // $('body').addClass('show-team-modal');
      // ## CUSTOM ICADE ## //
      var img = $(this).attr("data-img");
      var url = window.location.pathname;
      var urlsParts = url.split('/');
      if(urlsParts.length > 0 && urlsParts[1] == 'en'){
        var apiUrl = "/en/membre-modal/" + $(this).attr('data-id');
      } else {
        var apiUrl = "/membre-modal/" + $(this).attr('data-id');
      }

      $.ajax({
        url: apiUrl
      }).done(function (data) {
        // insert data into div
        $('.team-modal-portrait').html("<img src='" + img + "' srcset='" + img + "' alt=''>");
        $(".team-modal-content").html(data);
        $('body').addClass('show-team-modal');
        trapFocus($('.team-modal')[0]);
      });
    })

    $('.team-modal-close').on('click', function(e){
      e.preventDefault();
      $('body').removeClass('show-team-modal');
    })

    // push block
    $('.push-block-modal').on('click', function(e){
      e.preventDefault();

      let hrefValue = $(this).attr('href');
      document.body.style.overflow = 'hidden';

      $.ajax({
        url: hrefValue,
        type: 'GET',
        dataType: 'html',
        success: function(response) {
          $('#push-modal').html(response);

          $('.push-modal-close').on('click', function(e){
            document.body.removeAttribute('style');
            e.preventDefault();
            $('#push-modal').html("");
          })
        },
        error: function(error) {
          console.error('Erreur AJAX :', error);
        }
      });
    });

    $('.push-modal-close').on('click', function(e){
      e.preventDefault();
      $('body').removeUniqueId('push-modal-section');
    })

    // menu mobile
  $(document).ready(function() {
    // Ajouter ou supprimer la classe "position-fixed" lorsque le détail est ouvert ou fermé
    $('.sub-menu-details-toggle').on('toggle', function() {
      if ($(this).prop('open')) {
        $('.sub-menu').addClass('sub-menu-fixed');
      } else {
        $('.sub-menu').removeClass('sub-menu-fixed');
      }
    });
  });


    // faq
    $('.faq-list-question').on('click', function(e){
      e.preventDefault();

      if(!$(this).parent('li').hasClass('active')){

        let vh = $(window).height();
        let $currentParent = $(this).closest('.faq-list').find('> li.active');
        let $currentH = $currentParent.find('.faq-list-answer').height();
        let $current = $(this).closest('.faq-list').find('> li').removeClass('active').find('.faq-list-answer');
        let $newParent =  $(this).parent('li');
        let $new = $(this).parent('li').addClass('active').find('.faq-list-answer');

        $currentParent.find('.faq-list-question').attr('aria-expanded', 'false');
        $(this).attr('aria-expanded', 'true');


        $current.stop(true, true).slideUp(300);
        $new.stop(true, true).slideDown(300, function(){
          if(vh < $currentH){
            $('html, body').animate({
              scrollTop:$(this).parent('li').offset().top
            }, 'slow')
          }
        });

      } else {
        $(this).parent('li').removeClass('active').find('.faq-list-answer').stop(true, true).slideUp(300);
        $(this).attr('aria-expanded', 'false');
      }
    });

    // Chart
    var chartOptions = {
      type: 'line',
      data: {
        labels: [
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
          "76 EUR VEN 1 JUIN",
        ],
        datasets: [{
            data: [76,79,79,82,82,80,82,86,85,87,82,79,79,82,82,80,82,86,85,87],
            backgroundColor: "rgba(0, 133, 161, .3)",
            borderColor: "rgb(0, 133, 161)",
            pointHoverBackgroundColor: "rgb(4, 20, 45)",
            pointHoverBorderColor: "rgb(4, 20, 45)",
            fill: "start"
          }
        ]
      },
      options: {
        elements: {
          line: {
            tension: 0.000001
          }
        },
        title: {
            display: false
        },
        scales:{
            xAxes: [{
                display: false //this will remove all the x-axis grid lines
            }]
        },
        legend:{
          display: false
        },
        tooltips: {
          backgroundColor: "#0A1A32",
          callbacks: {
            label: function(tooltipItems, data) {
              return false;
            }
          },
          caretSize: 0,
          caretPadding: 5,
          cornerRadius: 4,
          titleFontSize: 12,
          titleMarginBottom: 0,
          xPadding: 6,
          yPadding: 4
        }
      },
      responsive: true
    };

  // ## CUSTOM ICADE ## //
  if(document.getElementById("exampleChart")){
      if(document.getElementById("exampleChart").classList.contains('no-fill')){
        chartOptions.data.datasets[0].backgroundColor = "rgba(0,0,0,0)";
        chartOptions.data.datasets[0].pointBorderWidth = 0;
        chartOptions.data.datasets[0].pointBorderColor = "rgba(0,0,0,0)";
      }
      var ctx = document.getElementById("exampleChart").getContext("2d");
      
      var exampleChart = new Chart(ctx, chartOptions);
    }

  if(document.getElementById("exampleChart2")){
    if(document.getElementById("exampleChart2").classList.contains('no-fill')){
      chartOptions.data.datasets[0].backgroundColor = "rgba(0,0,0,0)";
      chartOptions.data.datasets[0].pointBorderWidth = 0;
      chartOptions.data.datasets[0].pointBorderColor = "rgba(0,0,0,0)";
    }
    var ctx2 = document.getElementById("exampleChart2").getContext("2d");

    var exampleChart2 = new Chart(ctx2, chartOptions);
  }

    // Publications dropdowns

    $('.publications-filter-toggle').on('click', function(e){
      e.preventDefault();
      if(!$(this).parent().hasClass('disabled')){
        if(!$(this).parent().hasClass('open')){
          $('.publications-filter-item.open').removeClass('open');
          $(this).parent().addClass('open');
        } else {
          $('.publications-filter-item.open').removeClass('open');
        }
      }
    });

    $('body').on('click', function(e){
      if(!$(e.target).closest('.publications-filter-item.open').length) {
        $('.publications-filter-item.open').removeClass('open');
      }
    });

    // Publications dropdowns (mobile)

    $('.publications-filter-mobile-toggle').on('click', function(e){
      e.preventDefault();
      $('body').addClass('show-publications-filter-mobile');
    });

    $('.publications-filter-mobile-close').on('click', function(e){
      e.preventDefault();
      $('body').removeClass('show-publications-filter-mobile');
    });

    $('.publications-filter-mobile-list-toggle').on('click', function(e){
      e.preventDefault();
      if(!$(this).parent().hasClass('disabled')){
        var listItem = $(this).parent();
        if(listItem.hasClass('open')){
          listItem.removeClass('open').find('.publications-filter-mobile-sublist').stop(true, true).slideUp();
        } else {
          $('.publications-filter-mobile-list li.open').removeClass('open').find('.publications-filter-mobile-sublist').stop(true, true).slideUp();
          listItem.addClass('open').find('.publications-filter-mobile-sublist').stop(true, true).slideDown();
        }
      }
    });

    // Section year filter
    // var $yearFilterCurrValue = $('.section-filter-item').find('input:checked');
    // $('.section-filter-toggle').on('click', function(e){
    //   e.preventDefault();
    //   if(!$(this).parent().hasClass('open')){
    //     $('.section-filter-item.open').removeClass('open');
    //     $(this).parent().addClass('open');
    //   } else {
    //     $('.section-filter-item.open').removeClass('open');
    //   }
    // });
    //
    $('.publication-filter-submit').on('click', function(e){
      e.preventDefault();
      var ctn = $(this).closest('.publications-filter-item');
      var btn = ctn.find('.publications-filter-toggle');
      var count = ctn.find('input:checked').length;
      btn.removeClass('has-filters').find('.num').remove();
      if(count > 0){
        btn.addClass('has-filters').append('<span class="num"> ('+count+')</span>');
      }
      ctn.removeClass('open');
      // ## CUSTOM ICADE ## //
      var form = $(this).closest("form");
      form.submit();
    });
    //
    // $('body').on('click', function(e){
    //   if(!$(e.target).closest('.section-filter-item.open').length) {
    //     $('.section-filter-item.open').removeClass('open');
    //     var $input = $('.section-filter-item').find('input');
    //     $input.removeAttr('checked');
    //     // console.log($yearFilterCurrValue);
    //     // $yearFilterCurrValue.attr('checked', true);
    //
    //   }
    // });

  var yearFilter = function yearFilter(element) {

    this.$wrapper = element;
    this.$inputs = this.$wrapper.find('input');
    this.$currInput = this.$wrapper.find('input:checked');
    this.$toggle = this.$wrapper.find('.section-filter-toggle');
    this.$body = $('body');

    this.init = function () {
      this.bindEvents();
    };

    this.bindEvents = function () {
      this.$toggle.on('click', this.toggleDropdown.bind(this));
      this.$body.on('click', function (e) {
        if (!$(e.target).closest('.section-filter-item.open').length) {
          this.closeDropdown();
        }
      }.bind(this));
    };

    this.toggleDropdown = function (e) {
      e.preventDefault();

      if (this.$wrapper.hasClass('open')) {
        this.closeDropdown();
      } else {
        this.openDropdown();
      }
    };

    this.openDropdown = function () {
      this.$wrapper.addClass('open');

      // CUSTOM ICADE
      $(":radio[value="+$("#chiffreCleA").html()+"]").prop('checked', true);

    };

    this.closeDropdown = function () { 
      this.$wrapper.removeClass('open');
      this.resetInputs();
    };

    this.resetInputs = function () {
      this.$inputs.prop('checked', false);
      this.$inputs.removeAttr('checked');

      // CUSTOM ICADE
      $(":radio[value="+$("#chiffreCleA").html()+"]").prop('checked', true);
    };

    this.init();
  };

    $('.section-filter-item').each(function(){
      new yearFilter($(this));
    });


    // Talent reasons
    $('.talent-reasons-list-item').on('click keypress', function(e){
      if(e.type == 'keypress' && e.keyCode != 13){
        return false;
      }
      if(!$(this).hasClass('active')){
        $('.talent-reasons-list').addClass('has-open-item');
        $('.talent-reasons-list-item.active').removeClass('active').find('.talent-reasons-list-desc').stop(true, true).slideUp();
        $(this).addClass('active').find('.talent-reasons-list-desc').stop(true, true).slideDown();
      } else {
        $('.talent-reasons-list').removeClass('has-open-item');
        $('.talent-reasons-list-item.active').removeClass('active').find('.talent-reasons-list-desc').stop(true, true).slideUp();
        $(this).removeClass('active');
      }
    });

    $('.custom-checkbox, .custom-radio').on('keypress', function (e) {
      if(e.keyCode == 13){
        $(this).click();
      }
    })


    function trapFocus(element) {
      var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
      var firstFocusableEl = focusableEls[0];
      var lastFocusableEl = focusableEls[focusableEls.length - 1];
      var KEYCODE_TAB = 9;

      setTimeout(function () {
        firstFocusableEl.focus();
      }, 250)

      element.addEventListener('keydown', function(e) {
        var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

        if (!isTabPressed) {
          return;
        }

        if ( e.shiftKey ) /* shift + tab */ {
          if (document.activeElement === firstFocusableEl) {
            lastFocusableEl.focus();
            e.preventDefault();
          }
        } else /* tab */ {
          if (document.activeElement === lastFocusableEl) {
            firstFocusableEl.focus();
            e.preventDefault();
          }
        }
      });
    }

});
