import * as ScrollMagic from 'scrollmagic';
import {TimelineMax} from 'gsap';

export default class AchievementAnim {

    constructor(element){

        this.$wrapper = element;
        this.$image = this.$wrapper.find('.achievements-visual');
        this.$content = this.$wrapper.find('.achievements-info');
        this.$tags = this.$wrapper.find('.achievements-tags');
        this.$title = this.$wrapper.find('.achievement-title');
        this.$link = this.$wrapper.find('.achievement-link');

        this.controller = new ScrollMagic.Controller();
        this.timeline = new TimelineMax({pause:true});

        this.buildAnim();
    }


    buildAnim(){
        if(this.$image.length){
            this.timeline.fromTo(this.$image, 2, {alpha:0}, {alpha:1}, 'start');
        }
        if(this.$content.length){
            this.timeline.staggerFromTo(this.$title, 2, {x:'-15px', alpha:0}, {x:"0px", alpha:1}, 0.2, 'start+=0.5');
        }
        if(this.$link.length){
            this.timeline.fromTo(this.$link, 2, {x:'-15xp', alpha:0}, {x:"0px", alpha:1}, 'start+=0.5');
        }
        if(this.$tags.length){
            this.timeline.staggerFromTo(this.$tags.find('li'), 2, {x:'-15px', alpha:0}, {x:"0px", alpha:1}, 0.2, 'start+=0.5');
        }
        new ScrollMagic.Scene({
            triggerElement:this.$wrapper[0],
            offset:300,
            triggerHook:"onEnter"
        })
            .setTween(this.timeline)
            .addTo(this.controller);

    }

}
