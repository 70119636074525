export default class Select{

    constructor(element) {
        this.$wrapper = element;
        this.$placeholder = this.$wrapper.find('.js-select-placeholder');
        this.$select = this.$wrapper.find('.js-select-select');

        this.bindEvents();
    }


    bindEvents(){
        this.$select.on('change', this.updatePlaceholder.bind(this));
    }

    updatePlaceholder(){
        this.$placeholder.html(this.$select.val());
    }

}