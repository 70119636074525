window.intlTelInput = require('intl-tel-input');
require('intl-tel-input/build/js/utils');
export default class InputPhone{

    constructor(element) {
        this.$input = element;

        this.init();
    }


    init(){
        window.intlTelInput(this.$input[0], {
        });
    }


}