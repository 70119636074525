export default function chartSwitcher(wrapper) {
        const btns = wrapper.querySelectorAll('.js-chart-switcher-btn');
        const canvas = wrapper.querySelector('.js-chart-switcher-canvas');
        const select = wrapper.querySelector('.js-chart-switcher-select');
        const baseUrl = wrapper.dataset.url;
        let labels = [];
        let datasetsData = [];
        let chart = null;
        const days = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
        const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        init();
        bindEvents();

    function bindEvents(){
        btns.forEach(btn => {
            btn.addEventListener('click', () => {
                handleBtnClick(btn);
            });
        });

        if(select){
            select.addEventListener('change', handleSelectChange);
            select.addEventListener('click', handleSelectChange);
            select.addEventListener('touchstart', handleSelectChange);
        }
    }

    function handleSelectChange() {
        let selectedIndex = select.selectedIndex;
        let currentOption = select.options[selectedIndex];

        clearSelect();
        currentOption.setAttribute('selected', 'selected');

        ajaxCall(
            currentOption.dataset.from,
            currentOption.dataset.to,
            currentOption.dataset.maxpoints
        );

        document.querySelector('.js-select-placeholder').textContent = currentOption.text;
    }

    function clearSelect() {
        let options = select.options;

        for (let i=0, iLen=options.length; i<iLen; i++) {
            options[i].removeAttribute('selected');
        }
    }

    function handleBtnClick(btn){
        if(btn.classList.contains('tag-list-button-is-active')){
            return false;
        }

        btn.classList.add('tag-list-button-is-active');

        ajaxCall(
            btn.dataset.from,
            btn.dataset.to,
            btn.dataset.maxpoints
        );

        btns.forEach(element => {
            if (element !== btn) {
                element.classList.remove('tag-list-button-is-active');
            }
        });
    }

    function init(){
        let currentBtn = wrapper.querySelector('.js-chart-switcher-btn.tag-list-button-is-active');

        if (currentBtn) {
            ajaxCall(
                currentBtn.dataset.from,
                currentBtn.dataset.to,
                false
            );

            return true;
        }

        ajaxCall();
    }

    async function ajaxCall(from,to, maxpoints) {
        let params = {};

        if (from) {
            params.from = from;
        }

        if (to) {
            params.to = to;
        }

        if (maxpoints) {
            params.maxpoints = maxpoints;
        }

        const url = baseUrl + (new URLSearchParams(params)).toString();

        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            return false;
        }

        const data = await response.json();
        labels = [];
        datasetsData = [];

        data.response.forEach(stock => {
            let high = stock.High;
            high = high.substr(0, high.length - 2)
            let date = new Date(stock.Date);
            labels.push(`${high} EUR ${days[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`);
            datasetsData.push(high.replace(',', '.'));
        });
        updateChart();
    }

    function updateChart(){
        if(chart){
            chart.destroy();
        }
        chart = new Chart(canvas, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                    data: datasetsData,
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderColor: "rgb(0, 133, 161)",
                    pointBorderColor: "rgba(0, 0, 0, 0)",
                    pointBorderWidth: 0,
                    pointHoverBackgroundColor: "rgb(4, 20, 45)",
                    pointHoverBorderColor: "rgb(4, 20, 45)",
                    fill: "start"
                }]
            },
            options: {
                elements: {
                    line: {
                        tension: 0.000001
                    }
                },
                title: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        display: false //this will remove all the x-axis grid lines
                    }]
                },
                legend: {
                    display: false
                },
                tooltips: {
                    backgroundColor: "#0A1A32",
                    callbacks: {
                        label: function label(tooltipItems, data) {
                            return false;
                        }
                    },
                    cornerRadius: 4,
                    titleFontSize: 12,
                    xPadding: 5,
                    yPadding: 3
                }
            },
            responsive: true
        });
    }
}
